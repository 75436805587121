import { StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import styles from '../sass/components/dontDrinkAndDrive.module.scss';
import Container from './container';
import Link from './link';
import Social from './social';
import SvgAttention from '../svg/attention';

const DontDrinkAndDrive = ({
  title,
  topLinks,
  middleLinks,
  copyright,
  background,
}) => (
  <section className={styles.dontDrinkAndDrive} id="dont-drink-and-drive">
    <Img
      fluid={{
        ...background.fluid,
        src: `${background.url}?w=1024&fit=crop`,
        srcSet: `${background.url}?w=375&h=700&fit=crop 375w, ${
          background.url
        }?w=768&h=1024&fit=crop 768w, ${
          background.url
        }?w=1024&h=768&fit=crop 1024w, ${
          background.url
        }?w=1440&h=1000&fit=crop 1440w, ${
          background.url
        }?w=1920&h=1000&fit=crop 1920w, ${
          background.url
        }?w=2520&h=1200&fit=crop 2520w, ${
          background.url
        }?w=3840&h=2000&fit=crop 3840w`,
        sizes: `100vw`,
        srcSetWebp: `${background.url}?w=375&h=700&fit=crop&fm=webp 375w, ${
          background.url
        }?w=768&h=1024&fit=crop&fm=webp 768w, ${
          background.url
        }?w=1024&h=768&fit=crop&fm=webp 1024w, ${
          background.url
        }?w=1440&h=1000&fit=crop&fm=webp 1440w, ${
          background.url
        }?w=1920&h=1000&fit=crop&fm=webp 1920w, ${
          background.url
        }?w=2520&h=1200&fit=crop&fm=webp 2520w, ${
          background.url
        }?w=3840&h=2000&fit=crop&fm=webp 3840w`,
        srcWebp: `${background.url}?w=1024&fm=webp&fit=crop`,
      }}
      className={styles.dontDrinkAndDrive__background}
      imgStyle={{ objectPosition: '', objectFit: '' }}
      style={{ position: '' }}
      Tag="figure"
      alt={title}
    />

    <Container>
      <header className={styles.dontDrinkAndDrive__header}>
        <SvgAttention />
        <div className={styles.dontDrinkAndDrive__header__title} dangerouslySetInnerHTML={{ __html: title }} />
      </header>

      <nav className={styles.dontDrinkAndDrive__links}>
        {topLinks && (
          <ul className={styles.dontDrinkAndDrive__links__top}>
            {topLinks.map(link => (
              <li key={link.id}>
                <Link to={link.url}>{link.text}</Link>
              </li>
            ))}
          </ul>
        )}

        {middleLinks && (
          <ul className={styles.dontDrinkAndDrive__links__middle}>
            {middleLinks.map(link => (
              <li key={link.id}>
                <Link to={link.url}>{link.text}</Link>
              </li>
            ))}
          </ul>
        )}

        <Social />
      </nav>

      <footer className={styles.dontDrinkAndDrive__footer}>
        <p className={styles.dontDrinkAndDrive__footer__copy}>{copyright}</p>
      </footer>
    </Container>
  </section>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsDontDrinkAndDrive {
          title
          link
          copyright
          topLinks {
            text
            url
            id
          }
          middleLinks {
            text
            url
            id
          }
          background {
            url
            fluid(maxWidth: 3840) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    `}
    render={data => (
      <DontDrinkAndDrive {...data.datoCmsDontDrinkAndDrive} {...props} />
    )}
  />
)
