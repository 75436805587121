import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import styles from '../sass/components/lessons.module.scss';
import { getUserData } from '../utils/userData';
import Container from './container';
import Link from './link';
import getString from '../utils/get-string';

const Lessons = ({ lessons, strings }) => {
  return (
    <Container>
      <div className={styles.lessons}>
        {lessons.map(({ node }) => {
          const progressOptions = ({
            done = true,
            wrong = false,
            empty = false,
          }) => {
            const lessonTried =
              getUserData() &&
              getUserData().lessons.filter(lesson => lesson.id === node.id)

            return lessonTried && lessonTried.length > 0
              ? lessonTried[0].learned
                ? done
                : wrong
              : empty
          }

          const progressText = progressOptions({
            done: getString(strings, "11220975"),
            wrong: getString(strings, "11220976"),
            empty: node.number,
          })

          const imageName = progressOptions({
            done: 'done',
            wrong: 'again',
            empty: 'empty',
          })

          const progressImage = `/images/${imageName}.svg`

          const progressClassMidoficator = `lessons__item${imageName
            .charAt(0)
            .toUpperCase() + imageName.slice(1)}`

          return (
            <article
              key={node.id}
              id={node.url}
              className={`${styles.lessons__item} ${
                styles[progressClassMidoficator]
              }`}
            >
              <div className={styles.lessons__item__contentwrapper}>
                <header className={styles.lessons__item__header}>
                  <h3 className={styles.lessons__item__header__number}>
                    {progressText}
                  </h3>
                  <h4 className={styles.lessons__item__header__title}>
                    <Link to={`/${node.url}`}>{node.title}</Link>
                  </h4>
                </header>
                <div
                  className={styles.lessons__item__content}
                  dangerouslySetInnerHTML={{
                    __html: node.excerpt,
                  }}
                />
              </div>
              <figure className={styles.lessons__item__image}>
                <Img
                  fluid={{
                    ...node.image.fluid,
                    src: `${node.image.url}?w=350`,
                    srcSet: `${node.image.url}?w=320 320w, ${
                      node.image.url
                    }?w=380 380w, ${node.image.url}?w=440 440w, ${
                      node.image.url
                    }?w=500 500w, ${node.image.url}?w=560 560w, ${
                      node.image.url
                    }?w=620 620w, ${node.image.url}?w=740 740w`,
                    sizes: `(min-width: ${1200 / 16}em) ${Math.floor(
                      (368 / 16) * 100
                    ) / 100}em, (min-width: ${1024 /
                      16}em) calc((100vw - 40px - 25px*2) / 3), (min-width: ${768 /
                      16}em) ${Math.floor(((312 * 100) / 768) * 100) /
                      100}vw, ${Math.floor(((345 * 100) / 375) * 100) / 100}vw`,
                    srcSetWebp: `${node.image.url}?w=320&fm=webp 320w, ${
                      node.image.url
                    }?w=380&fm=webp 380w, ${
                      node.image.url
                    }?w=440&fm=webp 440w, ${
                      node.image.url
                    }?w=500&fm=webp 500w, ${
                      node.image.url
                    }?w=560&fm=webp 560w, ${
                      node.image.url
                    }?w=620&fm=webp 620w, ${node.image.url}?w=740&fm=webp 740w`,
                    srcWebp: `${node.image.url}?w=350&fm=webp`,
                  }}
                  className={styles.lessons__item__image__in}
                  style={{ position: '' }}
                  imgStyle={{ objectFit: '' }}
                  tag="figure"
                  alt={`${node.number} - ${node.title}`}
                />
                <div className={styles.lessons__item__play}>
                  {imageName !== "empty" && <img src={progressImage} alt={progressText} />}
                </div>
              </figure>
            </article>
          )
        })}
      </div>
    </Container>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allDatoCmsLesson(sort: { fields: position, order: ASC }) {
          edges {
            node {
              id
              title
              number
              url
              excerpt
              position
              image {
                fluid(maxWidth: 1000) {
                  ...GatsbyDatoCmsFluid
                }
                url
              }
            }
          }
        }

        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11220975", "11220976"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => (
      <Lessons lessons={data.allDatoCmsLesson.edges} strings={data.strings} {...props} />
    )}
  />
)
