import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import styles from '../sass/components/header.module.scss';
import CloseBtn from '../svg/close';
import Logo from '../svg/logo';
import UserIcon from '../svg/userIcon';
import { getUserData } from '../utils/userData';
import Container from './container';
import Link from './link';
import getString from '../utils/get-string';

const Header = ({ showCloseBtnWithUrl, strings }) => (
  <header className={styles.header}>
    <Container>
      <Link to="/" className={styles.header__logo}>
        <Logo />
      </Link>

      {showCloseBtnWithUrl ? (
        <Link to={showCloseBtnWithUrl} className={styles.header__close}>
          <span>{getString(strings, "11220927")}</span>
          <CloseBtn />
        </Link>
      ) : (
        getUserData() && (
          <div className={styles.header__loggeduser}>
            <p>{getUserData().name}</p>
            <UserIcon />

            <Link to="/log-out">
              {getString(strings, "11220926")}
              <UserIcon />
            </Link>
          </div>
        )
      )}
    </Container>
  </header>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        strings: allDatoCmsLanguageString(filter: {originalId: {in: ["11220927", "11220926"]}}) {
          edges {
            node {
              string
              original
              originalId
            }
          }
        }
      }
    `}
    render={data => <Header {...data} {...props} />}
  />
)
